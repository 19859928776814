@import url("https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&display=swap");

* {
  font-family: "Istok Web", sans-serif;
}

body {
  background-color: #f0f0f0;
}

h2 {
  font-size: 2rem;
  margin: 0.83rem;
}

h3 {
  margin: 0;
  font-size: 1.8rem;
}

p {
  line-height: 1.3rem;
  font-size: 1.1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #f0f0f0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-container {
  margin: 0 auto;
  margin-top: 70px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.PredEat-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
  margin-top: 3rem;
  flex-direction: column-reverse;
}

.PredEat-section h2 {
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 3.3rem;
}

section .left,
section .right {
  width: 80%;
  flex-direction: row;
  align-self: center;
  display: flex;
  justify-content: center;
}

section .left {
  flex-direction: column;
  display: inline-flex;
}

section .right {
  margin-top: 4rem;
}

.PredEat-section.slogan .left .Button-try {
  display: inline-flex;
  align-self: flex-end;
}

.PredEat-section.slogan .right .PredEat-plan {
  width: 360px;
}

section.steps .right {
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}

section.steps .right ul {
  line-height: 1.3rem;
  font-size: 1.1rem;
}

section.steps.ai-surprise-you .right {
  align-items: center;
}

.Button-try.center {
  margin: 0 auto;
  align-self: center;
}

.Button-try.white {
  background-color: #fff;
  color: #534a8c;
  border: 1px solid #534a8c;
  margin: unset;
  align-self: center;
}

.Button-footer-wrapper {
  display: flex;
}

.ul-footer {
  list-style: none;
  display: inline-flex;
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;
  align-self: center;
}

.ul-footer li {
  margin-right: 1rem;
}
.ul-footer li a {
  text-decoration: none;
}

.ul-footer li a:hover {
  color: #face3b;
}

@media (min-width: 1080px) {
  .App-container {
    max-width: 988px;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 700px) {
  .PredEat-section {
    flex-direction: row;
  }
  section .right {
    margin-top: 0rem;
  }
  section.steps .right {
    padding-left: 3rem;
  }
}
