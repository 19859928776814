.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  background-color: #534a8c;
  border-top: 1px solid #e0e0e0;
  flex-direction: column;
}

.footer-container {
  width: 100%;
}

.footer-container .footer-logo {
  height: 90px;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
  object-fit: contain;
}

.footer-container a {
  color: #fff;
  text-decoration: none;
}

.footer-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media (min-width: 1080px) {
  .footer-container {
    width: 988px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 700px) {
  .footer-links-container {
    flex-direction: row;
  }
}
