.app-banner-download-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 3rem;
}

.app-banner-download {
  display: flex;
  margin-top: 4rem;
  padding: 1rem;
  background-color: #fff;
  flex-direction: column;
}

.app-banner-download img {
  display: none;
}

.banner-label-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.banner-label {
  padding: 1.5rem;
  line-height: 2rem;
}

.Button-try.center.download-banner {
  margin: unset;
  align-self: flex-end;
}

.app-recipe {
  margin-top: 0;
}

.recipe {
  display: flex;
  flex-direction: column;
}

.recipe-wrapper {
  padding: 0 2rem;
}

.recipe-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: column;
}

.recipe-title {
  display: flex;
  align-items: center;
}
.recipe-title span {
  margin-right: 1rem;
}

.recipe-container {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
}
.recipe-container:first-child {
  margin-bottom: 2rem;
}

.PredEat-section.slogan .right .PredEat-plan {
  width: 240px;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .app-banner-download {
    flex-direction: row;
    padding: unset;
    padding-top: 1rem;
  }

  .app-banner-download img {
    height: 100%;
  }

  .recipe-header {
    align-items: center;
    flex-direction: row;
  }

  .recipe {
    flex-direction: row;
  }

  .recipe-container:first-child {
    margin-right: 2rem;
    margin-bottom: unset;
  }

  .recipe-wrapper {
    margin-bottom: 6rem;
  }

  .PredEat-section.slogan .right .PredEat-plan {
    width: 320px;
  }
}
