.Header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: #fff;
}

.Header-container {
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  padding: 0 2rem;
  box-sizing: border-box;
}

.PredEat-logo {
  height: 44px;
}

@media (min-width: 1080px) {
  .Header-container {
    max-width: 988px;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 700px) {
}
